import React from 'react';
//import ReactDOM from 'react-dom';
import {createRoot} from 'react-dom/client';
import { Provider } from 'react-redux';
import { configureStore, createStore, applyMiddleware, compose } from 'redux';
//import { configureStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

import reducers from './reducers';


import App from './App';
import './index.css';

//const store = configureStore(reducers, compose(applyMiddleware(thunk)));
const store = createStore(reducers, compose(applyMiddleware(thunk)));

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
    <Provider store={store}>
        <App />
    </Provider>
);
